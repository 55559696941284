.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "PangramBlack";   /*Can be any text*/
  src: local("PangramBlack"),
    url("./assets/fonts/pangram/Pangram-Black.otf") format("truetype");
}

@font-face {
  font-family: "PangramBold";   /*Can be any text*/
  src: local("PangramBold"),
    url("./assets/fonts/pangram/Pangram-Bold.otf") format("truetype");
}

@font-face {
  font-family: "PangramExtraBold";   /*Can be any text*/
  src: local("PangramExtraBold"),
    url("./assets/fonts/pangram/Pangram-ExtraBold.otf") format("truetype");
}

@font-face {
  font-family: "PangramExtraLight";   /*Can be any text*/
  src: local("PangramExtraLight"),
    url("./assets/fonts/pangram/Pangram-ExtraLight.otf") format("truetype");
}

@font-face {
  font-family: "PangramLight";   /*Can be any text*/
  src: local("PangramLight"),
    url("./assets/fonts/pangram/Pangram-Light.otf") format("truetype");
}

@font-face {
  font-family: "PangramMedium";   /*Can be any text*/
  src: local("PangramMedium"),
    url("./assets/fonts/pangram/Pangram-Medium.otf") format("truetype");
}

@font-face {
  font-family: "PangramRegular";   /*Can be any text*/
  src: local("PangramRegular"),
    url("./assets/fonts/pangram/Pangram-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Qairo";   /*Can be any text*/
  src: local("Qairo"),
    url("./assets/fonts/qairo/QAIRO.ttf") format("truetype");
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#payment-element {
  margin-bottom: 24px;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.cutive-mono-regular {
  font-family: "Cutive Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.arrows {
  width: 60px;
  height: 72px;
  left: 50%;
  bottom: 20px;
}

.arrows path {
  stroke: #b29a90;
  fill: transparent;
  stroke-width: 1px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}

.flip-card {
  background-color: transparent;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
/* .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
} */

.flip-card-initial {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}